import React from 'react'
import BannerImage from '../assets/images/monstar-bg.png'
import BlueImage from '../assets/images/monstar-blue-bg.png'
import Monstar1 from '../assets/images/monstar1.png'
import Monstar2 from '../assets/images/monstar2.png'
import Monstar3 from '../assets/images/monstar3.png'
import Monstar4 from '../assets/images/monstar4.png'
import Monstar5 from '../assets/images/monstar5.png'
import Monstar6 from '../assets/images/monstar6.png'
import MonstarSignal from '../assets/images/monstar-signal.png'
import MonstarAnalysis from '../assets/images/monstar-analysis.png'
import MonstarNews from '../assets/images/monstar-news.png'
import MonstarEducation from '../assets/images/monstar-education.png'
import MonstarEconomicCalendar from '../assets/images/monstar-economic-calendar.png'
import MonstarQR from '../assets/images/monstar-qr.png'
import MonstarLogos from '../assets/images/monstar-logos.png'

export const Homepage = () => {
  return (
    <>
    <div>
        <div className='grid md:grid-cols-12 md:text-left text-center pt-10 max-w-[1064px] mx-auto'>
            <div className='md:col-span-7 max-w-[360px] mx-auto pt-10'>
                <p className='text-monstrade-blue text-4xl pb-4 font-bold'>Get ready for more experience with Monstar!</p>
                <p>We have combined everything from breaking news updates, financial analysis and expectations, buy-sell signals, economic calendar, educational videos, to campaigns all in one single application!</p>
            </div>
            <div className='md:col-span-5'>
                <img className='max-w-[350px] pt-16 md:mx-0 mx-auto' src={BannerImage} alt="" />
            </div>
        </div>
        <div>
            <div className={"container-full lg:py-16 z-0 relative bg-cover'z-10'"} style={{ backgroundImage: `url("${BlueImage}")` }}>
                <div className='text-white text-center py-8'>
                    <p className='text-xl tracking-widest'>FEATURES</p>
                    <p className='text-2xl font-bold py-2'>Aim for the Summit in Forex with Monstar!</p>
                    <p className='text-sm max-w-[800px] mx-auto'>Monstar uygulamasında anlık olarak yayınlanan al-sat sinyalleri ile alım-satım yapabilir ve işlem durumlarını takip edebilirsiniz. Haftalık, aylık ve total olarak işlem başarısının ne kadar olduğunu görüntüleyebilirsiniz.</p>
                </div>
                <div className='grid sm:grid-cols-6 grid-cols-2 gap-8 max-w-[800px] mx-auto pb-8'>
                    <div><img className='max-w-[100px] mx-auto' src={Monstar1} alt="" /></div>
                    <div><img className='max-w-[100px] mx-auto' src={Monstar2} alt="" /></div>
                    <div><img className='max-w-[100px] mx-auto' src={Monstar3} alt="" /></div>
                    <div><img className='max-w-[100px] mx-auto' src={Monstar4} alt="" /></div>
                    <div><img className='max-w-[100px] mx-auto' src={Monstar5} alt="" /></div>
                    <div><img className='max-w-[100px] mx-auto' src={Monstar6} alt="" /></div>
                </div>
            </div>
        </div>
        <div className='relative'>
            <div className='grid lg:grid-cols-2 max-w-[1064px] mx-auto lg:text-left text-center py-16'>
                <div>
                    <img className='max-w-[400px] md:w-auto w-64 mx-auto' src={MonstarSignal} alt="" />
                </div>
                <div className='max-w-[480px] py-10 lg:mx-0 mx-auto'>
                    <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>SIGNALS</p>
                    <p className='text-3xl text-monstrade-blue py-2 font-semibold tracking-wider'>Take Solid Steps with Instant Trade Signals</p>
                    <p className='font-light'>Monstar's daily trade signals allow you to experience the Forex world in a healthier way. The AI-powered trading assistant provides new investors in the Forex world with the opportunity to make healthier trades, while offering higher potential returns for intermediate or professional investors.</p>
                </div>
            </div>
            <div class="signals-triangle"></div>
        </div>

        <div>
            <div className=' bg-[#F3F3F3]'>
                <div className='grid lg:grid-cols-2 lg:text-left text-center max-w-[1064px] mx-auto py-10'>
                    <div className='max-w-[480px] mx-auto pt-12'>
                        <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>ANALYSIS</p>
                        <p className='text-3xl text-monstrade-blue py-2 font-semibold tracking-wider'>Technical & Basic Analysis</p>
                        <p className='font-light'>Our expert analysts' reports contain detailed information about market trends, price movements, and other important factors. This allows investors to make more profitable trades by making the right decisions.</p>
                    </div>
                    <div>
                        <img className='max-w-[300px] mx-auto' src={MonstarAnalysis} alt="" />
                    </div>
                </div>    
            </div>
        </div>

        <div className='relative'>
            <div class="news-triangle"></div>
            <div className='grid lg:grid-cols-2 lg:text-left text-center max-w-[1064px] mx-auto pt-10'>
                <div>
                    <img className='max-w-[240px]  mx-auto' src={MonstarNews} alt="" />
                </div>
                <div className='max-w-[480px] lg:mx-0 mx-auto'>
                    <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>NEWS</p>
                    <p className='text-3xl text-monstrade-blue py-2 font-semibold tracking-wider'>Breaking News From the Finance World</p>
                    <p className='font-light'>Monstar, being up-to-date with news and analysis is crucial to succeed in the Forex market. Therefore, it tracks financial events worldwide every day and provides you with the latest Forex news, analysis, and comments. It provides all the tools you need to keep your finger on the pulse of the market.</p>
                </div>
            </div>
        </div>

        <div className='bg-[#F3F3F3]'>
            <div className='text-center py-10  max-w-[1064px] mx-auto'>
                <div className='max-w-[800px] mx-auto pt-12'>
                    <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>EDUCATION</p>
                    <p className='text-3xl max-w-[600px] mx-auto font-semibold text-monstrade-blue py-2 tracking-wider'>The Road to Professionalism Goes Through Good Education!</p>
                    <p className='font-light'>To achieve professionalism, it is not enough to just receive news and trading signals! Good education and informative videos about Forex are your most important tools on this process. With MonsTrade's special educational content in the Monstar application, you can reach professionalism faster.</p>
                </div>
            </div>
            <div>
                <img className='max-w-[760px] sm:w-auto w-80 mx-auto pb-20' src={MonstarEducation} alt="" />
            </div>
        </div>

        <div className='py-12'>
            <div className='border max-w-[1024px] mx-auto rounded-2xl shadow-xl mt-[-80px] bg-white'>
                <div className='max-w-[800px] text-center mx-auto pt-12'>
                    <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>ECONOMIC CALENDER</p>
                    <p className='text-3xl max-w-[600px] mx-auto font-semibold text-monstrade-blue py-2 tracking-wider'>Master All the Market Activities with Monstar!</p>
                    <p className='font-light'>With the economic calendar in Monstar, you no longer need an assistant! Monstar provides you with daily, weekly, and monthly events with an advanced economic calendar. This way, you can take positions according to events and important developments without the need for any other details, and make more informed trades.</p>
                </div>
                <div>
                    <img className='sm:max-w-[550px] sm:w-auto w-[280px] py-12 mx-auto' src={MonstarEconomicCalendar} alt="" />
                </div>
            </div>
        </div>
        
        <div className='bg-[#F3F3F3]'>
            <div className='max-w-[800px] text-center mx-auto pt-12'>
                <p className='text-3xl max-w-[450px] mx-auto font-semibold text-monstrade-blue py-2 tracking-wider'>Download Monstar from any Platform You Wish</p>
                <p className='font-light'>Download Monstar anytime and from anywhere to your Android or IOS device and start using it! Get all the tools you need for trading with MonsTrade, all in one application.</p>
            </div>
            <div>
                <img className='max-w-[100px] py-12 mx-auto' src={MonstarQR} alt="" />
            </div>
            <div>
                <img className='max-w-[300px] pb-12 mx-auto' src={MonstarLogos} alt="" />
            </div>
        </div>
    </div>
    </>
  )
}
