import React from 'react'
import Logo from '../assets/images/monstar-logo.png'

export const Footer = () => {
  return (
    <>
        <div className='border-t-2'>
            <img className='mx-auto py-10 max-w-[160px]' src={Logo} alt="" />
        </div>
    </>
  )
}
