import './App.css';
import React from 'react';
import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";

import { Layout } from './components/Layout';
import { Homepage } from './pages/Homepage';
import { PageNotFound } from './pages/PageNotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path='/docs'>
            <Route path='privacy-policy' element={<PrivacyPolicy />} />
            <Route path='terms-and-conditions' element={<TermsAndConditions />} />
          </Route>
        </Route>
        {/* <Route path='/docs' element={<Layout />}>
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        </Route> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
