import React from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from './Footer'
import { Navbar } from './Navbar'

export const Layout = () => {
  return (
    <>
      <Navbar/>
      <div className="max-w-[1920px] m-auto" id='main' >
          <Outlet/>
      </div>
      <Footer/>
    </>
  )
}
