import React from 'react'
import Logo from '../assets/images/monstar-logo.png'

let Links =[
    {name:"Homepage",link:"/"},
];

export const Navbar = () => {

return (
  <div className='shadow-lg w-full top-0 left-0  ' >
    <div className="items-center justify-between py-8   lg:px-10 px-7 ">
      <div className='font-bold text-2xl cursor-pointer justify-center flex items-center
       text-[#df8b45]'>
        <a href="/"><img src={Logo} className='mx-auto max-w-[160px]' /></a>
      </div>
    </div> 
  </div>
 
)

}